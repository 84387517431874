import request from '@/utils/request'
import store from '@/store'


const OtherService = {
  getSKPD
}


function getSKPD(){
  return request({
    //url:'/get-drop-downList-skpd',
    url:'/v1/get-drop-downList-skpd',
    method:'post',
  })
}


export default OtherService
